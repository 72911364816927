// 未提交，审批中，已通过，已驳回
export const approveStateColor = {
  未提交: '#999999',
  审批中: '#F78C00',
  已通过: '#61C876',
  已驳回: '#F7AD00',
};
export const PAGE_SIZE = 5;
// 跟进类型图片后缀名
export const FOLLOW_TYPE_IMG = {
  0: 'icon_jb.png',
  1: 'icon_tel.png',
  2: 'icon_wx.png',
  3: 'icon_dh.png',
  4: 'icon_email.png',
  5: 'icon_QQ.png',
  6: 'icon_other.png',
};
// 关注状态  是否关注 0否1本人关注2：下属关注3：本人及下属一起
export const COLLECTION_STATUS = {
  0: '#cccccc',
  1: '#ffeb3b',
  2: '#ffc300',
  3: '#ff8d1a',
};

// key
export const AK_KEY = 'THgpquFzQM3lmFV4BVuxxakZpnDaO4Me';
